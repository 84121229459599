<template>
  <material-chart-card
    id="monthlySourceChart"
    color="#ddd"
    library="apexcharts"
    type="treemap"
    :series="formattedSources"
    :options="options"
    :style="cssVars"
  >
    <h3 class="text-h6 font-weight-light">
      {{ $t("charts.specimen_sources") }}
    </h3>
    <div
      v-if="sources.labels.length && !hideTrend"
      class="category d-inline-flex font-weight-light"
    >
      <span
        v-if="sources.labels.length"
        class="mr-1"
      >
        {{ sources.series[0]?.reduce((a,b) => a + b) | separator }}
      </span>
      Positive Results in the Last {{ days }} Days
    </div>

    <template #actions>
      <v-icon
        class="mr-2"
        small
      >
        mdi-clock-outline
      </v-icon>
      <span class="text-caption grey--text font-weight-light">{{ $t('updated') }} {{ updated | duration('humanize', true) }}</span>
      <v-spacer />
      <card-reload
        :loading="loading.sources"
        @reload="fetchMonthlySources({ no_cache: true, days: days })"
      />
    </template>
  </material-chart-card>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    CardReload: () => import('@/components/CardReload.vue'),
  },
  props: {
    days: {
      type: Number,
      default: 30,
    },
    hideTrend: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
  }),
  computed: {
    ...mapState('charts', ['loading', 'sources']),
    formattedSources() {
      return [{
        data: this.sources.labels.map((source, i) => {
          source = this.$t(`sources.${source}`)
          let label = source === source.toUpperCase() ? source : this.$options.filters.capitalize(source)
          return {
            x: label,
            y: this.sources.series[0][i],
          }
        }),
      }]
    },
    updated() {
      return this.$moment(this.sources.time).diff()
    },
    cssVars() {
      return {
        '--font-size': Math.min(115 / this.days, 10) + "pt",
        '--stroke-width': Math.min(28 / this.sources.series[0]?.length, 8) + "%",
      }
    },
    options() {
      return {
        chart: {
          parentHeightOffset: 0,
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          style: {
            fontFamily: 'Roboto, sans-serif',
            fontWeight: 400,
          },
        },
        stroke: {
          width: 1,
          colors: ['#ddd'],
        },
        colors: [
          '#EC407A',
          '#7E57C2',
          '#5C6BC0',
          '#42A5F5',
          '#26A69A',
          '#66BB6A',
          // '#9CCC65',
          // '#D4E157',
          // '#FFEE58',
          '#FFCA28',
          '#FFA726',
          '#FF7043',
        ],
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (value) => this.$options.filters.numeral(value),
          },
        },
      }
    },
  },
  created () {
    if (!this.sources.series[0]?.length || this.$moment().diff(this.sources.time, 'd') > 1) {
      this.fetchMonthlySources({ no_cache: false, days: this.days })
    }
  },
  methods: {
    ...mapActions('charts', ['fetchMonthlySources']),
  },
}
</script>

<style lang="scss">
  #monthlySourceChart .ct-bar {
    stroke-width: var(--stroke-width) !important;
  }
</style>
